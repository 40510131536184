
import ReactMarkdown from "react-markdown";
import { Article } from "@/integrations/supabase/types/article";

interface ArticleContentProps {
  article: Article;
}

export const ArticleContent = ({ article }: ArticleContentProps) => {
  const generateSlug = (text: string) => {
    return text
      .toLowerCase()
      .replace(/[^a-z0-9 ]/g, '')
      .replace(/\s+/g, '-');
  };

  return (
    <article className="prose dark:prose-invert max-w-none">
      <h1 className="text-4xl font-serif font-normal mb-4">{article.title}</h1>
      <div className="text-muted-foreground mb-8">
        {new Date(article.created_at).toLocaleDateString('en-US', { 
          month: 'long', 
          day: 'numeric', 
          year: 'numeric'
        })}
      </div>
      <ReactMarkdown components={{
        h1: ({ children }) => {
          const id = generateSlug(children.toString());
          return <h1 id={id}>{children}</h1>;
        },
        h2: ({ children }) => {
          const id = generateSlug(children.toString());
          return <h2 id={id}>{children}</h2>;
        },
        h3: ({ children }) => {
          const id = generateSlug(children.toString());
          return <h3 id={id}>{children}</h3>;
        },
        h4: ({ children }) => {
          const id = generateSlug(children.toString());
          return <h4 id={id}>{children}</h4>;
        },
        h5: ({ children }) => {
          const id = generateSlug(children.toString());
          return <h5 id={id}>{children}</h5>;
        },
        h6: ({ children }) => {
          const id = generateSlug(children.toString());
          return <h6 id={id}>{children}</h6>;
        },
      }}>{article.content}</ReactMarkdown>
    </article>
  );
};
