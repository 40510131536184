
import { useState, useEffect } from "react";
import { X } from "lucide-react";
import { Article } from "@/integrations/supabase/types/article";
import { Progress } from "@/components/ui/progress";

interface ArticleHeaderBoxProps {
  article: Article;
}

interface Heading {
  id: string;
  text: string;
  level: number;
}

export const ArticleHeaderBox = ({ article }: ArticleHeaderBoxProps) => {
  const [visible, setVisible] = useState(true);
  const [progress, setProgress] = useState(0);
  const [headings, setHeadings] = useState<Heading[]>([]);

  useEffect(() => {
    const handleScroll = () => {
      // Calculate how far down the page the user has scrolled
      const scrollTop = window.scrollY;
      const docHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPercent = scrollTop / docHeight;
      setProgress(scrollPercent * 100);
    };

    // Add event listener
    window.addEventListener("scroll", handleScroll);

    // Extract headings from the article
    const extractHeadings = () => {
      const articleElement = document.querySelector('article');
      if (!articleElement) return;

      const headingElements = articleElement.querySelectorAll('h1, h2, h3, h4, h5, h6');
      
      const extractedHeadings: Heading[] = Array.from(headingElements).map((heading, index) => {
        const level = parseInt(heading.tagName.substring(1), 10);
        const text = heading.textContent || '';
        
        // Set an id on the heading if it doesn't have one
        if (!heading.id) {
          heading.id = `heading-${index}`;
        }
        
        return {
          id: heading.id,
          text,
          level
        };
      });
      
      // Filter out the article title (first h1)
      const filteredHeadings = extractedHeadings.slice(1);
      setHeadings(filteredHeadings);
    };
    
    // Run once after render to extract headings
    setTimeout(extractHeadings, 100);

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToHeading = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Only display when there are more than 2 headings
  if (!visible || headings.length <= 2) return null;

  return (
    <div className="fixed top-20 right-4 w-64 bg-white dark:bg-gray-800 rounded border border-gray-200 dark:border-gray-700 hidden md:block">
      <div className="p-4">
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-sm font-medium">{article.title}</h3>
          <button 
            onClick={() => setVisible(false)}
            className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
            aria-label="Close"
          >
            <X size={16} />
          </button>
        </div>
        
        <div className="border-t border-gray-200 dark:border-gray-700 pt-2 mt-1">
          <ul className="space-y-1">
            {headings.map((heading) => (
              <li key={heading.id}>
                <button
                  onClick={() => scrollToHeading(heading.id)}
                  className="text-xs text-green-600 dark:text-green-400 hover:underline text-left w-full"
                >
                  {heading.text}
                </button>
              </li>
            ))}
          </ul>
        </div>
        
        <div className="mt-3">
          <Progress value={progress} className="h-1" />
        </div>
      </div>
    </div>
  );
};
