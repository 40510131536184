
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Header } from "@/components/Header";
import { Footer } from "@/components/Footer";
import { ArticleLoading } from "@/components/article/ArticleLoading";
import { ArticleNotFound } from "@/components/article/ArticleNotFound";
import { ArticleContent } from "@/components/article/ArticleContent";
import { ArticleHeaderBox } from "@/components/article/ArticleHeaderBox";

const ArticlePage = () => {
  const { slug } = useParams();

  const { data: article, isLoading, error } = useQuery({
    queryKey: ['article', slug],
    queryFn: async () => {
      console.log('Attempting to fetch article with slug:', slug);
      
      const { data, error } = await supabase
        .from('articles')
        .select('*')
        .eq('slug', slug)
        .maybeSingle();
      
      if (error) {
        console.error('Error fetching article:', error);
        throw error;
      }
      
      if (!data) {
        console.log('No article found with slug:', slug);
        return null;
      }
      
      console.log('Article fetched successfully:', data);
      return data;
    },
  });

  if (error) {
    console.error('Error in article query:', error);
    return <ArticleNotFound />;
  }

  return (
    <div className="min-h-screen bg-background flex flex-col">
      <Header />
      <main className="flex-grow">
        <div className="max-w-[65ch] mx-auto px-6 md:px-8 py-8">
          {isLoading ? (
            <ArticleLoading />
          ) : !article ? (
            <ArticleNotFound />
          ) : (
            <>
              <ArticleContent article={article} />
              <ArticleHeaderBox article={article} />
            </>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ArticlePage;
